// Node Modules
import {
  helpCustomerRequest,
  requestOrganisations
} from "../../../utils/index"
import Vue from "vue/dist/vue";
import "./components";
import _ from 'lodash';

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export const ViewModel = {
  el: `#vue_controller`,
  template: `#helpCustomerPage-template`,
  data() {
    return {
      organisations: [],
      selectedOrg: null,
      msg: ""
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.$refs.select.select();
      this.$refs.select.$refs.search.focus();
    }, 250);

    const profile_url = window.SECURITY_API_URL + '/security-profile/v1';
    fetch(profile_url, {method: 'GET', credentials: 'include'})
    .then(res => {
      if (res.status === 200) {
        res.json().then(obj => {
          if (obj.roles.indexOf('HelpCustomer') === -1) {
            this.redirectToPortal();
          }
        });
      } else {
        this.redirectToPortal();
      }
    }).catch(res => {
      this.redirectToPortal();
    });

  },
  methods: {
    redirectToPortal() {
      window.location.href = window.PORTAL_URL;
    },
    onOrgSearch(search, loading) {
      this.organisations = [];
      this.msg = "";

      if (search.length >= 2) {
        loading(true);
        this.searchOrg(loading, search, this);
      }
    },
    searchOrg: _.debounce((loading, search, vm) => {
      requestOrganisations(`${window.ACC_MGT_URL}`, search).then(res => {
        if (res.status === 200) {
          res.json()
          .then(json => vm.organisations = json.sort(
              (a, b) => (a.shortName > b.shortName) ? 1 : -1) || []);
        } else {
          res.json()
          .then(json => vm.msg = json);
        }
        loading(false);
      });
    }, 350),
    helpCustomer(org) {
      helpCustomerRequest(window.TOKEN_SERVICE_URL, org)
      .then(res => {
        if (res.status === 200) {
          window.location = window.PORTAL_URL;
        } else {
          res.json()
          .then(json => this.msg = json.msg);
        }
      });
    },
  }
};

export const VueViewModal = new Vue(ViewModel);
