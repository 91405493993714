export const requestOrganisations = (url, search) =>
    fetch(url + `/organisations/search/${escape(search)}`, {
        method: 'GET',
        credentials: 'include'
    });

export const helpCustomerRequest = (url, orgShortName) =>
    fetch(url + `/help-customer/${escape(orgShortName)}`, {
      method: 'GET',
      credentials: 'include'
    });

export const requestUsers = (url, orgShortName) =>
    fetch(url + `/organisations/${escape(orgShortName)}/users`, {
      method: 'GET',
      credentials: 'include'
    });

export const requestOrgAdmin = (url, orgShortName) =>
    fetch(url + `/organisations/${escape(orgShortName)}/adminuser`, {
      method: 'GET',
      credentials: 'include'
    });

export const sendMagicLink = (url, orgAdmin) =>
    fetch(url + `/migrate`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        pcsUsername: orgAdmin.loginName,
        email: orgAdmin.emailAddress,
        firstName: orgAdmin.firstName,
        lastName: orgAdmin.lastName,
        lang: getLang(orgAdmin.language)
      }),
      headers: {"Content-Type": "application/json" }
    });

function getLang(lang) {
  if (lang.indexOf("nl") !== -1) {
    return "nl"
  } else if(lang.indexOf("de") !== -1) {
    return "de"
  } else {
    return "en"
  }
}